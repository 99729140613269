#cnil_banner_consent {
  display: none;
  background: #f5f5f5;
  color: #181818;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  font-family: "Roboto", sans-serif;
  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 16px;
    a {
      color: #181818;
      text-decoration: underline;
    }
  }
  .modalCookies__close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EBBA04F76-6772-4463-B594-CBC8679FEAE6%3C/title%3E%3Cg id='Mes-demandes' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ma-demande---onglet-pj' transform='translate(-217.000000, -1099.000000)' fill='%23FFFFFF'%3E%3Cg id='Group-18' transform='translate(108.000000, 560.000000)'%3E%3Cg id='Group-20' transform='translate(2.000000, 0.000000)'%3E%3Cg id='Group-13' transform='translate(41.000000, 400.000000)'%3E%3Cg id='Group-17' transform='translate(20.000000, 130.000000)'%3E%3Cg id='close' transform='translate(46.000000, 9.000000)'%3E%3Cpath d='M19.6208559,2.20776254 C19.9475641,1.88144982 20.0753723,1.40562453 19.9561364,0.95952547 C19.8369005,0.513426405 19.4887352,0.164826619 19.042791,0.0450402768 C18.5968469,-0.0747460649 18.1208734,0.0524794326 17.7941651,0.378792156 L9.99983534,8.17326177 L2.20775241,0.378792156 C1.70270524,-0.126264057 0.883862004,-0.126264051 0.378814844,0.378792169 C-0.126232317,0.88384839 -0.126232323,1.70270631 0.37881483,2.20776254 L8.17314462,9.99998527 L0.37881483,17.792208 C0.0521065992,18.1189221 -0.0754874893,18.5951182 0.0440960228,19.041418 C0.163679535,19.4877177 0.512273067,19.8363175 0.95856481,19.9559032 C1.40485655,20.0754888 1.88104417,19.9478925 2.20775241,19.6211784 L9.99983534,11.8267088 L17.7941651,19.6211784 C18.2992123,20.1256141 19.1175526,20.1251111 19.6219793,19.6200549 C20.126406,19.1149987 20.125903,18.2966438 19.6208559,17.792208 L11.8265261,9.99998527 L19.6208559,2.20776254 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px;
    &:before {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EBBA04F76-6772-4463-B594-CBC8679FEAE6%3C/title%3E%3Cg id='Mes-demandes' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ma-demande---onglet-pj' transform='translate(-217.000000, -1099.000000)' fill='%23FFFFFF'%3E%3Cg id='Group-18' transform='translate(108.000000, 560.000000)'%3E%3Cg id='Group-20' transform='translate(2.000000, 0.000000)'%3E%3Cg id='Group-13' transform='translate(41.000000, 400.000000)'%3E%3Cg id='Group-17' transform='translate(20.000000, 130.000000)'%3E%3Cg id='close' transform='translate(46.000000, 9.000000)'%3E%3Cpath d='M19.6208559,2.20776254 C19.9475641,1.88144982 20.0753723,1.40562453 19.9561364,0.95952547 C19.8369005,0.513426405 19.4887352,0.164826619 19.042791,0.0450402768 C18.5968469,-0.0747460649 18.1208734,0.0524794326 17.7941651,0.378792156 L9.99983534,8.17326177 L2.20775241,0.378792156 C1.70270524,-0.126264057 0.883862004,-0.126264051 0.378814844,0.378792169 C-0.126232317,0.88384839 -0.126232323,1.70270631 0.37881483,2.20776254 L8.17314462,9.99998527 L0.37881483,17.792208 C0.0521065992,18.1189221 -0.0754874893,18.5951182 0.0440960228,19.041418 C0.163679535,19.4877177 0.512273067,19.8363175 0.95856481,19.9559032 C1.40485655,20.0754888 1.88104417,19.9478925 2.20775241,19.6211784 L9.99983534,11.8267088 L17.7941651,19.6211784 C18.2992123,20.1256141 19.1175526,20.1251111 19.6219793,19.6200549 C20.126406,19.1149987 20.125903,18.2966438 19.6208559,17.792208 L11.8265261,9.99998527 L19.6208559,2.20776254 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 20px;
    }
  }
  .modalCookies__all-choices {
    display: none;
    &.modalCookies__all-choices--active {
      display: block;
    }
  }
  .modalCookies__btns {
    @media screen and (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
    [data-btn="cookieUpdate"] {
      @media screen and (max-width: 600px) {
        order: 3;
      }
    }
    [data-btn="cookieDecline"] {
      @media screen and (max-width: 600px) {
        order: 1;
      }
    }
    [data-btn="cookieAccept"] {
      @media screen and (max-width: 600px) {
        order: 2;
      }
    }
    .cookie-btn {
      border: none;
      border-radius: 0;
      font-size: 14px;
      font-size: 1.4rem;
      display: inline-block;
      min-height: 40px;
      line-height: 40px;
      padding: 0 20px;
      background: linear-gradient(30deg, #aff57a 0%, #45eeaa 100%);
      margin-right: 16px;
    }
  }
  #cookieValidate {
    @media screen and (max-width: 600px) {
      margin: auto;
      display: block;
    }
  }
  .modalCookies__all-choices__input {
    padding: 15px;
    display: flex;
    align-items: center;
    input,
    label {
      margin: 0;
    }
    input {
      display: none;
    }
    label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:before {
        content: "";
        width: 4em;
        height: 2em;
        border-radius: 2em;
        background-color: #ababab;
        display: block;
        margin-right: 10px;
      }
      &:after {
        content: "";
        width: 2em;
        height: 2em;
        border-radius: 2em;
        background-color: #fff;
        border: 2px solid #fff;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.3s ease;
      }
    }
    &.input-open {
      label {
        &:before {
          background-color: #dc143c;
        }
        &:after {
          background-color: #f0f0f0;
          border: 2px solid #dc143c;
          left: 2em;
        }
      }
    }
  }
}

.btn__enable-media {
  position: relative;
  z-index: 2;
  margin-left: 15px;
  border: none;
  border-radius: 0;
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
  min-height: 40px;
  line-height: 40px;
  padding: 0 20px;
  background: linear-gradient(30deg, #aff57a 0%, #45eeaa 100%);
  margin-right: 16px;
}
.bloc-media__target {
  iframe {
    width: 100%;
    height: 100%;
    min-height: 300px;
  }
}
